import { Controller } from 'stimulus'

export default class extends Controller {

    unmountPaymentElementFunction = null
    tokenizeFunction = null

    async connect () {
        console.log('NewPropelrPaymentMethodsController Connected')
        console.log(`api-key ${this.element.dataset.apiKey}`)
        console.log(`client-secret ${this.element.dataset.clientSecret}`)

        const paymentForm = await Forward.createPaymentElement({
            apiKey: this.element.dataset.apiKey,
            clientSecret: this.element.dataset.clientSecret,
        })

        this.unmountPaymentElementFunction = paymentForm.mount('#propelr-payment-form', {
            onChange: (event) => {
                if (!event.ready) {
                    console.log('Credit card input is incomplete');
                    // Disable submit/pay button  and show error message
                }
                console.log(event);
            },
            onReady: (tokenize) => {
                console.log('Credit card form is ready')
                tokenize()
            },
            onSuccess: async ({ token }) => {
                console.log('paymentMethodId', token)
                document.getElementById('connect_integrations_propelr_payment_methods_controller_form_propelr_payment_method_identifier').value = token
                document.getElementById('payment-method-submit-button').disabled = false
            }
        });
    }

    disconnect () {
        this.unmountPaymentElementFunction()
    }

}
