import {Controller} from 'stimulus'
import Cleave from 'cleave.js'

export default class extends Controller {
    connect() {
        console.log("currency-mask#connect", this.element)
        const options = {
            numeral: true,
            numeralDecimalScale: 2,
            prefix: '$',
            signBeforePrefix: true,
        }
        this.cleave = new Cleave(this.element, options)
    }
}
