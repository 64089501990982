import { Controller } from 'stimulus'

const MIN_HEIGHT = 200
const MAX_HEIGHT = 2000
const FALLBACK_SIZE = 1024

export default class extends Controller {
    observe (event) {
        if (window.ResizeObserver) {
            this.observer = new ResizeObserver(() => this.resize())
            this.observer.observe(this.element.contentDocument.body)
        }
        // do the initial resize
        this.resize()
        // just to be on the safe side, check again in a moment
        setTimeout(() => this.resize(), 1e3)
    }

    disconnect () {
        if (this.observer) {
            this.observer.disconnect()
        }
    }

    resize () {
        const iframe = this.element
        // reset height to force minimum scrollHeight computation
        iframe.style.height = 0
        const height = Math.min(Math.max(iframe.contentDocument.body.scrollHeight || FALLBACK_SIZE, MIN_HEIGHT), MAX_HEIGHT)
        console.log("classic-controller#resize", height)
        iframe.style.height = height + 'px'
    }
}
