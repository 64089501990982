import { Controller } from 'stimulus'

const SLIDE_SPEED = 200

export default class extends Controller {
    static targets = ["toggle", "drawer"]

    connect () {
        console.log('mobile-menu#connect')
        const toggle = $(this.toggleTarget)
        const spacer = $(this.drawerTarget)
        toggle.on('click', () => {
            toggle.toggleClass('open')
            spacer.slideToggle(SLIDE_SPEED, () => {
                spacer.toggleClass('open')
            })
        })
    }

}
