import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = [
    ]

    update () {
    }

    renderTotal (amount) {
        switch (this.element.dataset.type) {
            case 'percentage':
                return amount.toFixed(0) + '%'
            case 'currency':
            default:
                return '$' + amount.toFixed(2)
        }
    }
}
