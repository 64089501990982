import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ 'isHistoricalInput', 'localIdInput' ]

    connect() {
        console.log("certificate-of-election-controller#connect")

        $('.member-selector').select2({
            placeholder: 'Click/hit enter and type name...',
            minimumInputLength: 3,
            allowClear: true,
            ajax: {
                // transport: this.findMember,
                url: () => this.getMemberSearchPath(),
                data: ({ term: q }) => ({ q, local_id: this.localIdInputTarget.value }),
                dataType: 'json',
                delay: 500
            }
        })

        $('.any-member-selector').select2({
            placeholder: 'Click/hit enter and type name...',
            minimumInputLength: 3,
            allowClear: true,
            ajax: {
                // transport: this.findAnyMember,
                url: () => this.getAnyMemberSearchPath(),
                data: ({ term: q }) => ({ q }),
                dataType: 'json',
                delay: 500
            }
        })
    }

    getMemberSearchPath() {
        if (this.isHistoricalInputTarget.checked) {
            return "/search/select2_all_members_for_local"
        } else {
            return "/search/select2_active_members_for_local"
        }
    }

    getAnyMemberSearchPath() {
        if (this.isHistoricalInputTarget.checked) {
            return "/search/select2_all_members_for_group"
        } else {
            return "/search/select2_active_members_for_group"
        }
    }
}
