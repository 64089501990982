import { Controller } from 'stimulus'

const OPEN_CLASS = 'open-left-sidebar'

export default class extends Controller {
    static targets = ["content"]

    connect () {
        console.log('menu#connect')
    }

    toggle () {
        document.body.classList.toggle(OPEN_CLASS)
    }

    close (e) {
        if (!e.target.closest('.be-left-sidebar, .be-toggle-left-sidebar')) {
            document.body.classList.remove(OPEN_CLASS)
        }
    }

    load () {
        if (!this.contentTarget.dataset.loaded) {
            const { active, app } = this.contentTarget.dataset
            console.log('menu#load', `loading menu for ${active}`)
            $(this.contentTarget).load(`/${app || 'm2'}/menu?active=${active}`, (_, status, xhr) => {
                if (status === 'error') {
                    console.log('menu#load error', xhr)
                    delete this.contentTarget.dataset.loaded
                }
            })
            this.contentTarget.dataset.loaded = true
        }
    }

}
