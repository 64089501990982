import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = [
        "amount", "total"
    ]


    connect() {
        this.update()
    }

    update () {
        let total = 0
        for (const amount_field of this.amountTargets) {
            const amount = parseFloat(amount_field.value.replace(/[^\d.-]/g, ''))
            if (amount) {
                total += amount
            }
        }
        const stringTotal = this.renderTotal(total)
        for (const target of this.totalTargets) {
            if (target.tagName === 'INPUT') {
                target.value = total
                target.dispatchEvent(new Event('change'))
            } else {
                target.textContent = stringTotal
            }
        }
    }

    renderTotal (amount) {
        switch (this.element.dataset.type) {
            case 'percentage':
                return amount.toFixed(0) + '%'
            case 'currency':
            default:
                return '$' + amount.toFixed(2)
        }
    }
}
