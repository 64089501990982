import {Controller} from 'stimulus'
import { DirectUpload } from "activestorage"

export default class extends Controller {
    static targets = ["input", "preview"]

    connect () {
        console.log('direct-image-upload#connect')
        document.addEventListener('dragover', this.dragHandler.bind(this))
        document.addEventListener('drop', this.dropHandler.bind(this))
    }

    disconnect () {
        console.log('direct-image-upload#disconnect')
        document.removeEventListener('dragover', this.dragHandler.bind(this))
        document.removeEventListener('drop', this.dropHandler.bind(this))
    }

    render () {
        console.log('direct-image-upload#render')

        const input = this.inputTarget
        const preview = this.previewTarget

        for (const file of input.files) {
            const item = this.renderFile(file)
            preview.appendChild(item)
            this.uploadFile(file, item)
        }

        input.value = null
    }

    uploadFile (file, item) {
        const progress = item.querySelector('.direct-upload-progress')

        const input = this.inputTarget
        const url = input.dataset.directUploadUrl
        const upload = new DirectUpload(file, url, {
            directUploadWillStoreFileWithXHR (request) {
                request.upload.addEventListener("progress", event => {
                    const percent = 100 * event.loaded / event.total
                    progress.style.width = `${percent}%`
                })
            }
        })

        upload.create((error, blob) => {
            if (error) {
                item.classList.add('text-danger')
                console.error('direct-image-upload#uploadFile', error)
            } else {
                const signed_id = document.createElement('input')
                signed_id.type = "hidden"
                signed_id.value = blob.signed_id
                signed_id.name = input.name
                item.appendChild(signed_id)

                item.classList.remove('text-muted')
            }
            progress.style.opacity = 0
        })
    }

    renderFile (file) {
        const container = document.createElement('div')
        const item = document.createElement('div')
        const progress = document.createElement('div')
        const thumbnail = document.createElement('div')
        const button = document.createElement('button')
        const x = document.createElement('span')
        const name = document.createElement('div')
        const meta = document.createElement('div')
        container.className = 'col-6 col-md-4 col-lg-3 pb-3 center text-muted'
        container.appendChild(item)
        container.appendChild(name)
        container.appendChild(meta)
        item.className = 'img-thumbnail mb-2 shadow-sm position-relative img-no-pad'
        item.appendChild(progress)
        item.appendChild(thumbnail)
        progress.className = 'direct-upload-progress'
        progress.role = 'progressbar'
        thumbnail.className = 'w-100 square fade fit-bg-img'
        thumbnail.appendChild(button)
        this._renderThumbnail(file, thumbnail)
        name.className = 'mb-1'
        name.textContent = file.name
        meta.className = 'text-muted small'
        meta.textContent = `(${this._getHumanFileSize(file.size)})`
        button.className = 'close'
        button.type = 'button'
        button.ariaLabel = 'Remove'
        button.onclick = event => container.remove()
        button.appendChild(x)
        x.innerHTML = '&times;'
        x.ariaHidden = true
        return container
    }

    _renderThumbnail (file, preview) {
        const reader = new FileReader

        reader.onload = () => {
            preview.style.backgroundImage = `url(${reader.result})`

            // noinspection BadExpressionStatementJS
            preview.clientWidth // reflow in order to trigger transition on .show
            preview.classList.add('show')
        }

        reader.readAsDataURL(file)
    }

    _getHumanFileSize (size) {
        // inspired by https://stackoverflow.com/a/20732091
        const scale = size ? Math.min(Math.floor(Math.log(size) / Math.log(1024)), 2) : 0
        return +(size / Math.pow(1024, scale)).toFixed(2) + ' ' + ['Bytes', 'KB', 'MB'][scale]
    }

    dragHandler (event) {
        event.preventDefault()
    }

    dropHandler (event) {
        console.log('direct-image-upload#drop')
        if (event.dataTransfer.files.length === 1) {
            this.inputTarget.files = event.dataTransfer.files
            this.render()
        }
        event.preventDefault()
    }
}
