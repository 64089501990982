import { Controller } from 'stimulus'

export default class extends Controller {
    connect () {
        console.log("shortcut-controller#connect")
        this.listener = this.shortcuts.bind(this)
        document.addEventListener('keydown', this.listener)
    }

    disconnect () {
        console.log("shortcut-controller#disconnect")
        document.removeEventListener('keydown', this.listener)
    }

    shortcuts (event) {
        if (event.ctrlKey || event.metaKey) {
            if (event.key === this.element.dataset.key) {
                this.element.click()
                event.preventDefault()
            }
        }
    }
}
