import { Controller } from 'stimulus'

export default class extends Controller {
    visit(event) {
        $(this.data.get('element')).load(this.data.get('path'))
        // const { path } = this.element.dataset
        // if (path) {
        //     delete this.element.dataset.path
        //     $(this.element).load(path)
        // }
    }
}
