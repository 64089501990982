import { Controller } from 'stimulus'

const SLIDE_SPEED = 200

export default class extends Controller {
    static targets = ["content", "scroller"]

    connect () {
        console.log('sub-menu#connect')
        this.scroller = $(this.element.closest('.left-sidebar-scroll'))
        $("li.active", this.element).parents(".parent").addClass("active open")
        $('.parent > a', this.element).click(e => this._toggleSubmenu(e))
        this.scroller.perfectScrollbar()
    }

    _closeSubmenu (li) {
        console.log('sub-menu#_closeSubmenu', li)
        $(li.querySelector('ul')).slideUp(
            SLIDE_SPEED,
            () => {
                li.classList.remove('open')
                this.scroller.perfectScrollbar('update')
            }
        )
    }

    _openSubmenu (li) {
        console.log('sub-menu#_openSubmenu', li)
        $(li.querySelector('ul')).slideDown(
            SLIDE_SPEED,
            () => {
                li.classList.add('open')
                this.scroller.perfectScrollbar('update')
            }
        )
    }

    _toggleSubmenu (e) {
        console.log('sub-menu#_toggleSubmenu', e)
        const li = e.target.parentElement
        if (li.classList.contains('open')) {
            this._closeSubmenu(li)
        } else {
            // close all other menus
            const open = li.parentElement.querySelectorAll('li.open')
            for (const current of open) {
                this._closeSubmenu(current)
            }
            // open submenu
            this._openSubmenu(li)
        }
        e.preventDefault()
    }

}
