import { Controller } from 'stimulus'
import Rails from "rails-ujs"

export default class extends Controller {
    connect () {
        this.$picker = $(this.element).timepicker(this._buildOptions())
        this._keyHandler = this.handleKey.bind(this)
        this.element.addEventListener('keydown', this._keyHandler)
    }

    disconnect () {
        this.$picker.timepicker('remove')
        this.element.removeEventListener('keydown', this._keyHandler)
    }

    _buildOptions () {
        return {
            timeFormat: 'h:mm p',
            interval: 30,
            minTime: '12:00am',
            maxTime: '11:30pm',
            // defaultTime: '7:30pm',
            startTime: '9:00am',
            dynamic: false,
            dropdown: true,
            scrollbar: true
        }
    }

    handleKey (event) {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            this.scrollIntoView()
            event.preventDefault()
        }
    }

    scrollIntoView () {
        const selected = document.getElementById('ui-active-item')
        if (selected && selected.scrollIntoViewIfNeeded) {
            selected.scrollIntoViewIfNeeded(false)
        }
    }
}
