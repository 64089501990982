import {Controller} from 'stimulus'

const DEFAULT_TIMEOUT = 3000 // ms

export default class extends Controller {

    connect() {
        console.log("PollingController Connected")
        this.interval = parseInt(this.element.dataset.timeout) || DEFAULT_TIMEOUT
        this.url = this.element.dataset.url
        this.destination = this.element.dataset.destination
        this._schedulePoll()
    }

    _schedulePoll () {
        this.timer = setTimeout(() => this._poll(), this.interval)
    }

    _poll () {
        console.log("PollingController Polling")
        fetch(this.url)
            .then(response => response.json())
            .then(response => {
                if (response.result) {
                    this._redirect()
                } else {
                    this._schedulePoll()
                }
            })
            .catch(error => this._schedulePoll())
    }

    _redirect () {
        Turbolinks.visit(this.destination)
    }

    disconnect() {
        clearTimeout(this.timer)
    }

}
