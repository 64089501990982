import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        const { path } = this.element.dataset
        if (path) {
            delete this.element.dataset.path
            $(this.element).load(path)
        }
    }
}