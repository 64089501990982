import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

const INPUT_SELECTOR = 'input[type=text]'
const SAVE_TIMEOUT = 3000

export default class extends Controller {
    static targets = [
        "input"
    ]

    saving = false
    last_saved_value = null
    save_timer = null

    connect() {
        console.log("spreadsheet#connect")

        this.last_saved_value = this.getValue()

        this.cell = $(this.element).closest('td')
        this.row = this.cell.parent()
        this.body = this.row.parent()
        this.table = this.body.parent()

        if ($.fn.dataTable.isDataTable(this.table)) {
            this.datatable = this.table.DataTable()
        }
    }

    getValue() {
        return parseFloat(this.inputTarget.value.replace(/[^\d.-]/g, '')) || 0
    }

    save() {
        let new_value = this.getValue()
        if (!new_value) this.inputTarget.value = ''
        if (this.saving || this.last_saved_value === new_value) return
        this.saving = true
        this.last_saved_value = new_value

        if (this.save_timer) {
            clearTimeout(this.save_timer)
            this.save_timer = null
        }

        console.log("spreadsheet#save", this.inputTarget.value)

        Rails.fire(this.element, 'submit')
    }
    
    getPrevRow () {
        const prev = this.row.prev()
        if (prev.length) {
            return prev
        } else if (this.datatable) {
            const { page } = this.datatable.page.info()
            if (page > 0) {
                this.datatable.page('previous').draw(false)
                return this.body.children().last()
            }
        }
    }
    
    getNextRow () {
        const next = this.row.next()
        if (next.length) {
            return next
        } else if (this.datatable) {
            const { page, pages } = this.datatable.page.info()
            if (page < pages - 1) {
                this.datatable.page('next').draw(false)
                return this.body.children().first()
            }
        }
    }

    cursorAtPosition(position) {
        return this.inputTarget.selectionStart === position || this.inputTarget.selectionEnd === position
    }

    moveLeft() {
        this.cell.prev().find(INPUT_SELECTOR).focus().select()
    }

    moveRight() {
        this.cell.next().find(INPUT_SELECTOR).focus().select()
    }

    moveUp() {
        const index = this.row.find(INPUT_SELECTOR).index(this.inputTarget)
        const prevRow = this.getPrevRow()
        if (prevRow) {
            prevRow.find(INPUT_SELECTOR).eq(index).focus().select()
        }
    }

    moveDown() {
        const index = this.row.find(INPUT_SELECTOR).index(this.inputTarget)
        const nextRow = this.getNextRow()
        if (nextRow) {
            nextRow.find(INPUT_SELECTOR).eq(index).focus().select()
        }
    }

    handleKey(event) {
        console.log("spreadsheet#handleKey", event.key)

        if (this.save_timer) {
            clearTimeout(this.save_timer)
            this.save_timer = null
        }

        // cancel default submission and manually handle save on enter
        if (event.key === 'Enter') {
            this.save()
            this.moveDown()
        }

        if (event.key === 'ArrowLeft') {
            // only navigate left when cursor is at the start of the cell
            if (!this.cursorAtPosition(0)) return
            this.moveLeft()
        } else if (event.key === 'ArrowRight') {
            // only navigate right when cursor is at the end of the cell
            if (!this.cursorAtPosition(this.inputTarget.value.length)) return
            this.moveRight()
        } else if (event.key === 'ArrowUp') {
            this.moveUp()
        } else if (event.key === 'ArrowDown') {
            this.moveDown()
        }

        // only allow decimal digits and navigation
        const allowed = ('0' <= event.key && event.key <= '9') ||
            event.key === '.' || event.key === '-' ||
            event.key === 'Tab' || event.key === 'Backspace' || event.key === 'Delete'

        if (allowed) {
            this.save_timer = setTimeout(this.save.bind(this), SAVE_TIMEOUT)
        } else {
            event.preventDefault()
            return false
        }
    }

    update(event) {
        console.log("spreadsheet#update", event)

        const [data, status, xhr] = event.detail
        let {response, code} = xhr
        response = JSON.parse(response)

        if ("amount" in response) {
            // amount was rejected, server provided real value
            this.inputTarget.value = response.amount || ''
            this.last_saved_value = this.getValue()
            this.row.effect("highlight", {color: "lightcoral"}, 1500)
        } else {
            // update was accepted, flash to indicate save success
            this.row.effect("highlight", {color: "palegreen"}, 1500)
            if (this.datatable) {
                // update datatables sort order
                this.cell[0].dataset.order = this.last_saved_value
                this.datatable.cell(this.cell).invalidate()
            }
        }

        if ("total_amount" in response) {
            this.row.children().last().text(response.total_amount || '')
        }

        this.saving = false
    }

    error(event) {
        console.log("spreadsheet#error", event)

        this.saving = false

        const [data, status, xhr] = event.detail
        let {response, status: code} = xhr
        code = Math.floor(code / 100)

        if (code === 0 || code === 5) {
            alert("Something went wrong. We're bringing you back.")
        } else {
            response = JSON.parse(response)
            alert(response.error)
        }

        location.reload()
    }
}
