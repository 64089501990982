import {Controller} from 'stimulus'
import { DirectUpload } from "activestorage"

export default class extends Controller {
    static targets = ["input", "preview"]

    connect () {
        console.log('direct-upload#connect')
        document.addEventListener('dragover', this.dragHandler.bind(this))
        document.addEventListener('drop', this.dropHandler.bind(this))
    }

    disconnect () {
        console.log('direct-upload#disconnect')
        document.removeEventListener('dragover', this.dragHandler.bind(this))
        document.removeEventListener('drop', this.dropHandler.bind(this))
    }

    render () {
        console.log('direct-upload#render')

        const input = this.inputTarget
        const preview = this.previewTarget

        for (const file of input.files) {
            const item = this.renderFile(file)
            preview.appendChild(item)
            this.uploadFile(file, item)
        }

        input.value = null
    }

    uploadFile (file, item) {
        const progress = item.querySelector('.direct-upload-progress')

        const input = this.inputTarget
        const url = input.dataset.directUploadUrl
        const upload = new DirectUpload(file, url, {
            directUploadWillStoreFileWithXHR (request) {
                request.upload.addEventListener("progress", event => {
                    const percent = 100 * event.loaded / event.total
                    progress.style.width = `${percent}%`
                })
            }
        })

        upload.create((error, blob) => {
            if (error) {
                item.classList.add('text-danger')
                console.error('direct-upload#uploadFile', error)
            } else {
                const signed_id = document.createElement('input')
                signed_id.type = "hidden"
                signed_id.value = blob.signed_id
                signed_id.name = input.name
                item.appendChild(signed_id)

                item.classList.remove('text-muted')
            }
            progress.style.opacity = 0
        })
    }

    renderFile (file) {
        const item = document.createElement('div')
        const progress = document.createElement('div')
        const icon = document.createElement('span')
        const name = document.createElement('span')
        const button = document.createElement('button')
        const x = document.createElement('span')
        item.className = 'list-group-item text-muted'
        item.appendChild(progress)
        item.appendChild(icon)
        item.appendChild(name)
        item.appendChild(button)
        progress.className = 'direct-upload-progress'
        progress.role = 'progressbar'
        icon.className = `icon icon-inline mdi mdi-${this._getIconForFileType(file.type)}`
        name.textContent = `${file.name} (${this._getHumanFileSize(file.size)})`
        button.className = 'close'
        button.type = 'button'
        button.ariaLabel = 'Remove'
        button.onclick = event => item.remove()
        button.appendChild(x)
        x.innerHTML = '&times;'
        x.ariaHidden = true
        return item
    }

    _getIconForFileType (mime) {
        const [type, subtype] = mime.split('/', 2)
        switch (type) {
            case 'audio':
                return 'audio'
            case 'image':
                return 'image'
            case 'text':
                return 'file-text'
            case 'video':
                return 'movie'
            default:
                return 'file'
        }
    }

    _getHumanFileSize (size) {
        // inspired by https://stackoverflow.com/a/20732091
        const scale = size ? Math.min(Math.floor(Math.log(size) / Math.log(1024)), 2) : 0
        return +(size / Math.pow(1024, scale)).toFixed(2) + ' ' + ['Bytes', 'KB', 'MB'][scale]
    }

    dragHandler (event) {
        event.preventDefault()
    }

    dropHandler (event) {
        console.log('direct-upload#drop')
        if (event.dataTransfer.files.length === 1) {
            this.inputTarget.files = event.dataTransfer.files
            this.render()
        }
        event.preventDefault()
    }
}
