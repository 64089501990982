import {Controller} from 'stimulus'

export default class extends Controller {
    connect () {
        console.log("nested-selector#connect")
        const parents = this.element.querySelectorAll('select[data-child]')
        for (const parent of parents) {
            this._update(parent)
        }
    }

    update (event) {
        this._update(event.target)
    }

    _update (parent) {
        console.log("nested-selector#update", parent.name)
        const parent_index = this._resolveIndex(parent)

        // find the children of this parent
        const child_id = parent.dataset.child
        const children = this.element.querySelectorAll(parent_index ? `select[name*=${child_id}][name*=\\[${parent_index}\\]]` : `select[name*=${child_id}]`)

        for (const child of children) {
            this._updateChild(parent, child)
        }
    }

    _updateChild (parent, child) {
        // update the visible options
        const parent_id = parent.value
        for (const option of child.options) {
            const option_parent = option.dataset.parent
            if (option_parent) {
                const hide = option_parent !== parent_id
                if (hide) option.selected = false
                option.hidden = option.disabled = hide
            }
        }

        // recurse in case the child change impacted grandchild
        if (child.dataset.child) {
            this._update(child)
        }
    }

    _resolveIndex (element) {
        const match = element.name.match(/\[(\d+)]/)
        return match && match[1]
    }
}
