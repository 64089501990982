import {Controller} from 'stimulus';
import {loadConnectAndInitialize} from '@stripe/connect-js';
import {csrfToken} from 'rails-ujs'


export default class extends Controller {
    connect() {
        console.log("stripe-dashboard-payments#connect")
        const fetchClientSecret = async () => {

            // Fetch the AccountSession client secret
            const response = await fetch(
                `/integrations/stripe/account_sessions?stripe_account_id=${this.element.dataset.stripeAccountId}`,
                {
                    method: "POST",
                    headers: {
                        'X-CSRF-Token': csrfToken()
                    },
                }
            );

            if (response.ok) {
                const {client_secret} = await response.json();
                document.querySelector('#stripe-payments-error-container').setAttribute('hidden', '');
                return client_secret;
            } else {
                // Handle errors on the client side here
                const {error} = await response.json();
                console.error('An error occurred: ', error);
                document.querySelector('#error').removeAttribute('hidden');
                // return undefined;
            }
        }

        const stripeConnectInstance = loadConnectAndInitialize({
            // This is a placeholder - it should be replaced with your publishable API key.
            // Sign in to see your own test API key embedded in code samples.
            // Don’t submit any personally identifiable information in requests made with this key.
            publishableKey: this.element.dataset.stripePublishableKey,
            fetchClientSecret: fetchClientSecret,
        });

        const paymentsContainer = document.getElementById("stripe-payments-container");
        if (paymentsContainer != null) {
            const paymentComponent = stripeConnectInstance.create("payments");
            paymentsContainer.appendChild(paymentComponent);
        }

        const payoutsContainer = document.getElementById("stripe-payouts-container");
        if (paymentsContainer != null) {
            const payoutsComponent = stripeConnectInstance.create("payouts")
            payoutsContainer.appendChild(payoutsComponent);
        }

        const notificationBannerContainer = document.getElementById("stripe-notification-banner-container");
        if (notificationBannerContainer != null) {
            const notificationBanner= stripeConnectInstance.create('notification-banner');
            notificationBannerContainer.appendChild(notificationBanner);
        }
    }

    disconnect() {
        console.log("stripe-dashboard-payments#disconnect")
        const paymentsContainer = document.getElementById("stripe-payments-container");
        if (paymentsContainer != null) {
            paymentsContainer.innerHTML = ''
        }

        const payoutsContainer = document.getElementById("stripe-payouts-container");
        if (paymentsContainer != null) {
            payoutsContainer.innerHTML = ''
        }

        const notificationBannerContainer = document.getElementById("stripe-notification-banner-container");
        if (notificationBannerContainer != null) {
            notificationBannerContainer.innerHTML = ''
        }

    }


}
