import {Controller} from 'stimulus'
import Cleave from 'cleave.js'

export default class extends Controller {
    connect() {
        console.log("date-mask#connect", this.element)
        const options = {
            date: true,
            delimiter: '/',
            datePattern: ['m', 'd', 'Y'],
        }
        this.cleave = new Cleave(this.element, options)
    }
}
