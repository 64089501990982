import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        console.log("tab-loader#connect")

        // load tab content via AJAX the first time the tab is selected
        $(this.element).on('show.bs.tab', event => {
            const { path } = event.target.dataset
            if (path) {
                delete event.target.dataset.path
                const tabContentID = $(event.target).attr('href')
                $(tabContentID).load(path)
            }
        })

        // activate the default tab
        const defaultTab =
            this.element.querySelector('li a.active') ||
            this.element.querySelector('li a[data-default-tab]') ||
            this.element.querySelector('li:first-child a')
        $(defaultTab).tab('show')
    }
}