import { Controller } from 'stimulus'

export default class extends Controller {
    connect () {
        $(this.element).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-fade',
            removalDelay: 300,
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: 'Image #%curr% could not be loaded.',
                titleSrc: function(item) {
                    return item.el.attr('title')
                }
            },
            zoom: {
                enabled: true,
                duration: 300,
                opener: function(element) {
                    return element.find('img')
                }
            }
        })
    }
}
