import { Controller } from 'stimulus'

export default class extends Controller {
    select2

    connect() {
        console.log("live-search#connect")
        const { local, returnPath, placeholder, width, parent, searchPath, submit } = this.element.dataset

        const options = {
            placeholder,
            width,
            minimumInputLength: 3,
            allowClear: true,
            ajax: {
                url: searchPath || `/locals/${local}/affiliation_search`,
                data: ({ term }) => ({ q: term }),
                dataType: 'json',
                delay: 500
            },
            templateResult: (data, container) => {
                if (data.color) {
                    container.style.color = data.color
                }
                container.textContent = data.text
                return $(container)
            }
        }

        if (parent) {
            options.dropdownParent = $(parent)
        }

        this.select2 = $(this.element).select2(options)

        if (returnPath) {
            this.select2.on('select2:select', function (e) {
                Turbolinks.visit(`/locals/${local}/financials/affiliations/${e.params.data.id}/${returnPath}`)
            })
        } else if (submit) {
            this.select2.on('select2:select', this.submit.bind(this))
        }

        // select2 iOS focus bug workaround
        $('.select2-container').on('click', () => $('.select2-search__field').focus())

        // override default tab behavior
        this.select2.one('select2:open', () => {
            $('.select2-search__field').beforeOn('keydown', event => {
                if (event.which === 9) { // tab
                    // this prevents select2 from receiving the event
                    event.stopImmediatePropagation()
                    // do not move focus
                    event.preventDefault()
                }
            })
        })
    }

    disconnect() {
        console.log("live-search#disconnect", this.select2)
        this.select2.data('select2') && this.select2.select2('destroy')
        this.select2.off('select2:select')
    }

    submit() {
        this.element.form.querySelector('input[type=submit]').click()
    }
}
