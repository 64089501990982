import { Controller } from 'stimulus'

export default class extends Controller {
    show (event) {
        event.preventDefault()

        $("#md-default").html(`
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button aria-hidden="true" class="close" data-dismiss="modal" type="button"> 
                            <span class="mdi mdi-close"></span> 
                        </button>
                    </div>
                    <div class="modal-body">
                        <img class="image-size-constrained" src="${this.data.get('path')}" />
                    </div>
                </div>
            </div>
        `).modal()
    }
}
