import {Controller} from 'stimulus'
import CountUp from 'countUp.js'

export default class extends Controller {
    connect() {
        console.log("count-up#connect", this.element)

        const {
            prefix = '',
            suffix = '',
            start = 0,
            end = 0,
            decimals = 0,
            duration = 2.5
        } = this.element.dataset

        if (+end) {
            this.count = new CountUp(this.element, start, end, decimals, duration, {suffix, prefix})

            this.count.start()
        }
    }
}
