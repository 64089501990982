import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['inputElement']

    connect() {
        console.log("FOOOOOOOOO")
    }

    initialize() {
        console.log("FOOOOOOOOO")
        $(`#${this.data.get('element-id')}`).select2({
            placeholder: this.data.get('placeholder-text'),
            minimumInputLength: 3,
            ajax: {
                url: "/locals/ajax_search",
                data: function (params) {
                    return {q: params.term};
                },
                dataType: 'json',
                delay: 500
            }
        })
        if (this.inputElementTarget.value === '') {
            $(this.inputElementTarget).select2('open')
        }
    }
}
