import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = [
        "amount", "total"
    ]

    connect () {
        this.update()
    }

    update () {
        let total = 0
        for (const amount_target of this.amountTargets) {
            if (amount_target.checked) {
                total += parseFloat('amount' in amount_target.dataset ? amount_target.dataset.amount : 1)
            }
        }
        this.totalTarget.textContent = this.renderTotal(total)
    }

    selectAll (event) {
        for (const amount_target of this.amountTargets) {
            amount_target.checked = event.target.checked
        }
        this.update()
    }

    renderTotal (amount) {
        switch (this.element.dataset.type) {
            case 'percentage':
                return amount.toFixed(0) + '%'
            case 'currency':
                return '$' + amount.toFixed(2)
            default:
                return amount.toString()
        }
    }
}
