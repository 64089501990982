import { Controller } from 'stimulus'

export default class extends Controller {

    /*
    Save and restore expanded accordions using sessionStorage
     */

    connect () {
        this.saveId = `accordion_state_${location.pathname}_${this.element.id}`
        this.load()
    }

    disconnect () {
        this.save()
    }

    save () {
        const state = Array.from(this.element.querySelectorAll('.collapse.show')).map(e => e.id)
        console.log("persist-accordion#save", state)
        sessionStorage.setItem(this.saveId, JSON.stringify(state))
    }

    load () {
        const state = JSON.parse(sessionStorage.getItem(this.saveId))
        if (state) {
            console.log("persist-accordion#load", state)
            for (const id of state) {
                const element = document.getElementById(id)
                const toggle = this.element.querySelector(`[data-target="#${id}"]`)
                if (element) {
                    element.classList.add('show')
                }
                if (toggle) {
                    toggle.setAttribute('aria-expanded', true)
                }
            }
        }
    }
}
