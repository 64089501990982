import { Controller } from 'stimulus'

const DEFAULT_HEIGHT = 500

export default class extends Controller {
    connect() {
        console.log("summernote#connect", this.element)
        $(this.element).summernote(this._buildOptions())
    }

    _buildOptions () {
        return {
            minHeight: parseInt(this.element.dataset.height) || DEFAULT_HEIGHT
        }
    }
}
