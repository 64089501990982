import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [
        "start", "end"
    ]

    connect () {
        const start_date = $(this.startTarget).datetimepicker(this._buildOptions())
        const end_date = $(this.endTarget).datetimepicker(this._buildOptions())

        start_date.on('changeDate', event => {
            console.log("date-range", "start", event.date)
            end_date.datetimepicker('setStartDate', event.date)
        })

        end_date.on('changeDate', event => {
            console.log("date-range", "end", event.date)
            start_date.datetimepicker('setEndDate', event.date)
        })
    }

    _buildOptions () {
        return {
            minView: 2,
            autoclose: true,
            format: 'mm/dd/yyyy',
            bootcssVer: 4,
            navIcons: {
                leftIcon: 'mdi-chevron-left',
                rightIcon: 'mdi-chevron-right',
                iconType: 'mdi'
            },
            todayBtn: true,
            todayHighlight: true
        }
    }
}
