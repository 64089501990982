import {Controller} from 'stimulus'

export default class extends Controller {
    calendar

    connect() {
        console.log("calendar#connect")
        this.calendar = $(this.element).fullCalendar(this._buildOptions())
    }

    disconnect() {
        console.log("calendar#disconnect")
        this.calendar.fullCalendar('destroy')
    }

    _buildOptions() {
        return {
            defaultView: 'list',
            eventLimit: true,
            header: {
                left: 'prev, next',
                center: 'title',
                right: 'today,list,month'
            },
            views: {
                list: {
                    duration: {days: 60},
                    listDayAltFormat: 'dddd'
                }
            },
            events: this.element.dataset.url,
            timezone: false,
            eventRender: (event, element) => {
                if (this.calendar.fullCalendar('getView').name === 'listYear') {
                    var title = element.find(".fc-list-item-title")
                    title.append("<br />" + event.description)
                }
                if (!event.title.indexOf("Cancel")) {
                    title = element.find(".fc-list-item-title");
                    title.css("color", "red");
                }
            }
        }
    }

    foo() {
        var selectedCalendarIds = [];
        var calendar;
        var defaultView = 'list';

        try {
            var defaultCalendarViewRegex = /defaultCalendarView=(list|month)/g;
            var defaultCalendarViewMatch = defaultCalendarViewRegex.exec(document.cookie);
            if (defaultCalendarViewMatch && defaultCalendarViewMatch.length > 0) {
                defaultView = defaultCalendarViewMatch[1];
            }
        } catch (err) {
            console.log("Error retrieving default calendar view from cookie.");
        }

        var defaultDate = "#{@calendarStartDate}" || null;

        $(document).ready(function () {
            $('#calender-select-button').on('click', function () {

                $(".calendar-selection-checkbox").each(function () {
                    var calendarId = parseInt($(this).data("calendar-id"));
                    $(this).prop("checked", selectedCalendarIds.includes(calendarId));
                });

                $("#calendar-selection-modal").modal('show');
            });

            calendar = $('.calendar').fullCalendar({
                defaultDate: defaultDate,
                defaultView: defaultView,
                eventLimit: true,
                header: {
                    left: 'prev, next',
                    center: 'title',
                    right: 'today, list, month'
                },
                views: {
                    list: {
                        duration: {months: 3},
                        listDayAltFormat: 'dddd'
                    }
                },
                events: '#{calendar_event_feed_connect_connect_group_calendars_path(@connect_group)}',
                timezone: false,
                eventRender: function (event, element) {
                    if ($('.calendar').fullCalendar('getView').name === 'listYear') {
                        title = element.find(".fc-list-item-title");
                        title.append("<br />" + event.description);
                    }
                    const hostname = window.location.hostname
                    if (element.context.tagName === "A") {
                        if (element.context.hostname !== hostname) {
                            element.attr("target", "_blank");
                        }
                    } else {
                        title = element.find('a');
                        if (title[0].hostname !== hostname) {
                            title.attr("target", "_blank");
                        }
                    }
                    if (!event.title.indexOf("Cancel")) {
                        title = element.find(".fc-list-item-title");
                        title.css("color", "red");
                    }
                },
                loading: function (isLoading, view) {
                    if (isLoading) {
                        $("#loading-events").show();
                        $(".fc-view").css('background-color', '#f7f7f7');
                    } else {
                        $("#loading-events").hide();
                        $(".fc-view").css('background-color', 'transparent');
                    }
                },
                eventLimitClick: function (cellInfo, jsEvent) {
                    $("#day-events-title").text(cellInfo.date.format("dddd, MMMM Do YYYY"));
                    $("#day-events-event-container").empty();
                    cellInfo.segs.forEach(function (seg) {
                        const templateHTML =
                            `<a href="${seg.event.url}" style="text-decoration: none;">
                                <div class="additional-event-area" style="background-color: ${seg.event.color}; border-color: ${seg.event.color}">
                                    <div class="fc-content">
                                        <span class="fc-time">${seg.event.start.format("h:mma")}</span>
                                        <span class="fc-title">${seg.event.title}</span>
                                    </div>
                                </div>
                            </a>`
                        $("#day-events-event-container").append(templateHTML);
                    });
                    $("#day-events-modal").modal('show');
                }
            });

            $('#day-events-modal').on('shown.bs.modal', function () {
                $(".modal-body").scrollTop(0);
            })

            $("#calendar-selection-ok").on("click", function () {
                var calendarIds = []

                $(".calendar-selection-checkbox").each(function () {
                    if ($(this).prop("checked")) {
                        var calendarId = parseInt($(this).data("calendar-id"));
                        calendarIds.push(calendarId);
                    }
                });

                $.ajax({
                    type: "POST",
                    url: "/calendar/preferrences",
                    data: {
                        selected_calendar_ids: calendarIds
                    },
                    success: function (events) {
                        location.reload();
                    }
                });
            });

            $('.fc-list-button').click(function () {
                document.cookie = "defaultCalendarView=list";
            });
            $('.fc-month-button').click(function () {
                document.cookie = "defaultCalendarView=month";
            });
        });
    }
}
