import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [
        "selector"
    ]

    connect () {
        // Stimulus explicitly excludes targets from self-nested controllers
        // in order to support nested presets, we must select our targets manually
        this.allFieldTargets = this.element.querySelectorAll(':scope ' + this.targets.getSelectorForTargetName('field'))

        if (this.hasSelectorTarget) this.update()
    }

    update (event) {
        const option = this.hasSelectorTarget ?
            this.selectorTarget.options[this.selectorTarget.selectedIndex] :
            event.target
        console.log('presets-controller#update', option, option.dataset.values)

        const values = option.dataset.values ? JSON.parse(option.dataset.values) : null

        for (let i = 0; i < this.allFieldTargets.length; i++) {
            const field = this.allFieldTargets[i]
            if (values && values.length > i) {
                field.value = values[i]
                if (this.hasSelectorTarget) field.setAttribute('readonly', true)
            } else {
                if (this.hasSelectorTarget) field.removeAttribute('readonly')
            }
        }

        if (values) {
            for (const field of this.allFieldTargets) {
                field.dispatchEvent(new Event('change'))
            }
        }
    }
}
