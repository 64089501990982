import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
    static targets = [
        "form", "formHolder", "button",
        "display", "selector", "dues"
    ]

    saving = false

    edit() {
        console.log("edit-member-types#click")

        if (this.hasFormTarget) {
            $(this.buttonTarget).hide()
            $(this.formTarget).show()
        } else {
            $(this.formHolderTarget).load(this.formHolderTarget.dataset.path, () => {
                $(this.buttonTarget).hide()
                $(this.formTarget).show()
            })
        }
    }

    save() {
        if (this.saving) return
        this.saving = true

        const before = this.selectorTarget.querySelector("option[selected=selected]")
        const after = this.selectorTarget.options[this.selectorTarget.selectedIndex]

        // update selected attribute so that changes persist in turbolinks cache
        before && before.removeAttribute("selected")
        after.setAttribute("selected", "selected")

        const { text: name, value: id } = after

        console.log("edit-member-types#save", name, id)
        Rails.fire(this.formTarget, 'submit')

        if (this.hasDisplayTarget) {
            $(this.displayTarget).text(name)

            $(this.formTarget).hide()
            $(this.buttonTarget).show()
        }
    }

    update(event) {
        console.log("edit-member-types#update", event)

        const [data, status, xhr] = event.detail
        let {response, code} = xhr
        response = JSON.parse(response)

        if (this.hasDuesTarget) {
            $(this.duesTarget).text(response.dues_rate)
        }

        $(this.formTarget).closest("tr").effect("highlight", {color: "palegreen"}, 1500)

        this.saving = false
    }

    error(event) {
        console.log("edit-member-types#error", event)

        const [data, status, xhr] = event.detail
        let {response, status: code} = xhr
        code = Math.floor(code / 100)

        if (code === 0 || code === 5) {
            alert("Something went wrong. We're bringing you back.")
        } else {
            response = JSON.parse(response)
            alert(response.error)
        }

        location.reload()
    }
}
