import { Controller } from 'stimulus'

/* Text Sync Controller
 * updates display targets textContent whenever #update is called
 * from an input element with name matching data-name
 */

export default class extends Controller {
    static targets = [
        "display"
    ]

    update (event) {
        console.log('text-sync#update', event)
        const { name, value } = event.target
        for (const display of this.displayTargets) {
            if (display.dataset.name === name) {
                display.textContent = value
            }
        }
    }
}
